<template>
    <v-dialog max-width="300" :disabled="disabled" v-model="dialog">
        <!--Activator-->
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <v-text-field
                    class="rounded-lg labelColor"
                    background-color="appWhite"
                    :clearable="clearable"
                    :disabled="disabled"
                    :hint="hint"
                    :error="error"
                    :error-messages="errorMessage"
                    flat
                    hide-details="auto"
                    :label="label"
                    outlined
                    :persistent-hint="persistentHint"
                    :placeholder="placeholder || label"
                    :prepend-inner-icon="prependInnerIcon"
                    readonly
                    @click:clear="handleClear"
                    :value="computedFormatDate"
                />
            </div>
        </template>

        <!--Content-->
        <v-date-picker
            @change="handleDateSelection"
            color="grey6"
            :disabled="disabled"
            first-day-of-week="1"
            header-color="primary"
            :max="computedMaxDate"
            :min="computedMinDate"
            show-current
            selected-items-text="fff"
            v-model="pickerDate"
        />
    </v-dialog>
</template>

<script>
export default {
    name: 'DatePicker',

    props: [
        'clearable',
        'date',
        'disabled',
        'error',
        'errorMessage',
        'futureOnly',
        'hint',
        'label',
        'pastOnly',
        'persistentHint',
        'prependInnerIcon',
        'placeholder',
        'maxDate',
        'minDate'
    ],

    data: () => ({
        dialog: false,
        pickerDate: ''
    }),

    computed: {
        /**
         * Computed Format Date
         *
         * Return a new formatted date from the picker, if there is one.
         * Otherwise, return a formatted date from the DB.
         *
         * @returns {string} formatted date
         */
        computedFormatDate() {
            const t = this

            if (t.pickerDate) return t.MIX_formatDate(new Date(t.pickerDate).getTime(), 'long')

            if (t.$props.date) return t.MIX_formatDate(t.$props.date, 'long')
        },

        /**
         * Computed Min Date
         *
         * Calculate and return the min allowed date from the min-date array props [value, uom, tense].
         *
         * @returns {string} ISO formatted string
         */
        computedMinDate() {
            const t = this
            let newDate

            let dateNow = new Date()
            let value = t.$props.minDate?.length ? t.$props.minDate[0] : 0
            let uom = t.$props.minDate?.length ? t.$props.minDate[1] : 'years'
            let tense = t.$props.minDate?.length ? t.$props.minDate[2] : 'past'

            if (tense === 'past') {
                if (uom === 'years') newDate = dateNow.setFullYear(dateNow.getFullYear() - value)
                if (uom === 'months') newDate = dateNow.setMonth(dateNow.getMonth() - value)
                if (uom === 'days') newDate = dateNow.setDate(dateNow.getDate() - value)
            }

            if (tense === 'future') {
                if (uom === 'years') newDate = dateNow.setFullYear(dateNow.getFullYear() + value)
                if (uom === 'months') newDate = dateNow.setMonth(dateNow.getMonth() + value)
                if (uom === 'days') newDate = dateNow.setDate(dateNow.getDate() + value)
            }

            return new Date(newDate).toISOString()
        },

        /**
         * Computed Max Date
         *
         * Calculate and return the max allowed date from the max-date array props [value, uom, tense].
         *
         * @returns {string} ISO formatted string
         */
        computedMaxDate() {
            const t = this
            let newDate

            let dateNow = new Date()
            let value = t.$props.maxDate?.length ? t.$props.maxDate[0] : 0
            let uom = t.$props.maxDate?.length ? t.$props.maxDate[1] : 'years'
            let tense = t.$props.maxDate?.length ? t.$props.maxDate[2] : 'past'

            if (tense === 'past') {
                if (uom === 'years') newDate = dateNow.setFullYear(dateNow.getFullYear() - value)
                if (uom === 'months') newDate = dateNow.setMonth(dateNow.getMonth() - value)
                if (uom === 'days') newDate = dateNow.setDate(dateNow.getDate() - value)
            }

            if (tense === 'future') {
                if (uom === 'years') newDate = dateNow.setFullYear(dateNow.getFullYear() + value)
                if (uom === 'months') newDate = dateNow.setMonth(dateNow.getMonth() + value)
                if (uom === 'days') newDate = dateNow.setDate(dateNow.getDate() + value)
            }

            return new Date(newDate).toISOString()
        }
    },

    methods: {
        /**
         * Handle Date Selection
         *
         * Take the date selected from the Date Picker and emit it back to the parent (ChallengesForm.vue).
         * Close the picker when done.
         *
         * @param date the ISO date from the picker
         */
        handleDateSelection(date) {
            const t = this
            let newDate = new Date(date).getTime().toString()

            t.$emit('emitDate', newDate)

            t.dialog = false
        },

        /**
         * Handle Clear
         *
         * Handle the clear button click on the text field.
         * Reset the local picker date and emit a clear event to the parent.
         */
        handleClear() {
            const t = this

            // Clear internal state
            t.pickerDate = ''

            // Emit clear event to parent
            t.$emit('clear')
        }
    },

    watch: {
        // Watch for changes to the date prop to keep local state in sync
        date(newVal) {
            if (!newVal && this.pickerDate) {
                this.pickerDate = ''
            }
        }
    }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
    border-color: transparent;
}

.labelColor >>> .v-label {
    color: var(--v-primary-base) !important;
    opacity: 0.5;
}
</style>
