<!--
App Icon

Add all icons here for central management.
Icons take props for:
 - color (the colour for the icon)
 - icon - (the given name for the icon - not the icons8 name)
 - size - (the size for the icon)
-->
<template>
    <div>
        <div v-for="item in icons" :key="item.name + item.reference">
            <v-icon v-if="icon === item.name" :class="item.reference" :color="color" :disabled="disabled" :size="size" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppIcon',

    props: ['color', 'disabled', 'icon', 'size'],

    data: () => ({
        icons: [
            // Pages
            { name: 'home', reference: 'icons8-home' },
            { name: 'accreditation', reference: 'icons8-badge' },
            { name: 'events', reference: 'icons8-calendar' },
            { name: 'eventsDashboardPre', reference: 'icons8-event-accepted-tentatively' },
            { name: 'eventsDashboardDay', reference: 'icons8-event-accepted' },
            { name: 'fireRollCall', reference: 'icons8-building-on-fire' },
            { name: 'noticeBoard', reference: 'icons8-noticeboard' },
            { name: 'organisation', reference: 'icons8-business-building' },
            { name: 'qualification', reference: 'icons8-certificate' },
            { name: 'eye', reference: 'icons8-eye' },
            { name: 'car', reference: 'icons8-car' },
            { name: 'sites', reference: 'icons8-skyscrapers' },
            { name: 'qrCode', reference: 'icons8-qr-code' },
            { name: 'teams', reference: 'icons8-business-group' },
            { name: 'users', reference: 'icons8-people' },
            { name: 'support', reference: 'icons8-online-support' },
            { name: 'user', reference: 'icons8-user' },
            { name: 'logOut', reference: 'icons8-shutdown' },
            { name: 'tasks', reference: 'icons8-tasks' },
            { name: 'payroll', reference: 'icons8-money' },
            { name: 'history', reference: 'icons8-time-machine' },

            // App Icon
            { name: 'add', reference: 'icons8-add' },
            { name: 'archived', reference: 'icons8-archive' },
            { name: 'arrowBack', reference: 'icons8-back' },
            { name: 'arrowForward', reference: 'icons8-forward' },
            { name: 'camera', reference: 'icons8-camera' },
            { name: 'cancel', reference: 'icons8-cancel' },
            { name: 'check', reference: 'icons8-done' },
            { name: 'close', reference: 'icons8-close' },
            { name: 'closedEye', reference: 'icons8-closed-eye' },
            { name: 'columns', reference: 'icons8-select-column' },
            { name: 'comment', reference: 'icons8-comment' },
            { name: 'delete', reference: 'icons8-trash' },
            { name: 'disabled', reference: 'icons8-assistive-technology' },
            { name: 'draft', reference: 'icons8-circle' },
            { name: 'edit', reference: 'icons8-edit' },
            { name: 'email', reference: 'icons8-at-sign' },
            { name: 'error', reference: 'icons8-error' },
            { name: 'export', reference: 'icons8-export' },
            { name: 'filter', reference: 'icons8-slider' },
            { name: 'info', reference: 'icons8-info' },
            { name: 'menuDots', reference: 'icons8-menu-vertical' },
            { name: 'menuLines', reference: 'icons8-menu' },
            { name: 'minibus', reference: 'icons8-shuttle-bus' },
            { name: 'missingImage', reference: 'icons8-no-image' },
            { name: 'onHold', reference: 'icons8-private' },
            { name: 'pending', reference: 'icons8-minus' },
            { name: 'phone', reference: 'icons8-phone' },
            { name: 'print', reference: 'icons8-print' },
            { name: 'reserved', reference: 'icons8-r' },
            { name: 'reservedParking', reference: 'icons8-valet-parking' },
            { name: 'save', reference: 'icons8-save' },
            { name: 'search', reference: 'icons8-search' },
            { name: 'send', reference: 'icons8-email-send' },
            { name: 'settings', reference: 'icons8-settings' },
            { name: 'success', reference: 'icons8-checkmark-yes' },
            { name: 'termsConditions', reference: 'icons8-terms-and-conditions' },
            { name: 'time', reference: 'icons8-clock' },
            { name: 'truck', reference: 'icons8-truck' },
            { name: 'upload', reference: 'icons8-upload' },
            { name: 'userDeleted', reference: 'icons8-denied' }
        ]
    })
}
</script>

<style scoped></style>
