<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <div
        v-else
        class="d-flex flex-column justify-space-between primary"
        :style="$vuetify.breakpoint.width < 600 ? 'min-height: 100vh' : 'min-height: calc(100vh - 64px)'"
    >
        <!--Main Items --------------------------------------------------------------------------------------------- -->
        <div>
            <!--Menu Items-->
            <v-list nav>
                <!--Nav Item-->
                <v-list-item
                    v-for="item in computedMenu"
                    :key="item.name"
                    @click="MIX_go(item.path)"
                    class="sideMenu-item"
                    :class="$route.path === item.path && 'appGrey'"
                    dense
                    link
                >
                    <!--Icon-->
                    <app-icon class="mr-4" :color="$route.path === item.path ? 'primary' : 'appWhite'" :icon="item.icon" />

                    <!--Name-->
                    <app-text class="mt-1" :color="$route.path === item.path ? 'primary' : 'appWhite'">
                        {{ item.name }}
                    </app-text>
                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script>
import appConfig from '../../../appConfig.json'
export default {
    name: 'AppSideNavigation',

    data: () => ({
        isLoading: true,
        menu: [
            { name: 'Home', path: '/', icon: 'home' },
            { name: 'Accreditation', path: '/accreditation', icon: 'accreditation' },
            { name: 'Events', path: '/events', icon: 'events' },
            { name: 'Fire Roll Call', path: '/fireRollCall', icon: 'fireRollCall' },
            { name: 'Pre Event Dashboard', path: '/preEventDashboard', icon: 'eventsDashboardPre' },
            { name: 'Event Day Dashboard', path: '/eventDayDashboard', icon: 'eventsDashboardDay' },
            { name: 'Mass Emails', path: '/massEmails', icon: 'email' },
            { name: 'My Accreditation', path: '/myAccreditation', icon: 'accreditation' },
            { name: 'My Organisation', path: '/myOrganisation', icon: 'organisation' },
            { name: 'Notice Board', path: '/noticeBoard', icon: 'noticeBoard' },
            { name: 'Organisations', path: '/organisations', icon: 'organisation' },
            { name: 'Parking', path: '/parking', icon: 'car' },
            { name: 'Payroll', path: '/payroll', icon: 'payroll' },
            { name: 'Qualifications', path: '/qualifications', icon: 'qualification' },
            { name: 'Reporting', path: '/reporting', icon: 'eye' },
            { name: 'Sites', path: '/sites', icon: 'sites' },
            { name: 'Swapp', path: '/swapp', icon: 'qrCode' },
            { name: 'Teams', path: '/teams', icon: 'teams' },
            { name: 'ToDoList', path: '/toDoList', icon: 'noticeBoard' },
            { name: 'Users', path: '/users', icon: 'users' },
            { name: 'SWAPP Tasks', path: '/swappTasks', icon: 'tasks' },
            { name: 'SWAPP History', path: '/swappHistory', icon: 'history' }
        ],

        // Data
        fullCurrentUserData: {}
    }),

    computed: {
        /**
         * Computed Menu
         *
         * Filter the menu items based on the user's level, and the allowed routes.
         *
         * @returns {*|*[]} - The filtered menu items.
         */
        computedMenu() {
            const t = this
            const CURRENT_USER_LEVEL = t.fullCurrentUserData?.userData?.userLevel
            const USER_NAME = t.fullCurrentUserData?.userData?.userName

            if (!CURRENT_USER_LEVEL) return []

            // Fetch path strings for the user level
            let ALLOWED_PATHS_FOR_USER_LEVEL = appConfig.userMenus[CURRENT_USER_LEVEL]
            if (!ALLOWED_PATHS_FOR_USER_LEVEL) return []

            // Filter the menu items
            let filteredMenu = t.menu.filter(
                (item) => ALLOWED_PATHS_FOR_USER_LEVEL.includes(item.path) && appConfig.allowedRoutes.some((route) => route.path === item.path)
            )

            // Add Developer Page menu item if userName is 'Vindico Developer'
            if (USER_NAME === 'Vindico Developer') {
                filteredMenu.push({
                    path: '/developerPage',
                    name: 'Developer Page'
                })
            }

            return filteredMenu
        }
    },

    methods: {
        /**
         * Load Data
         *
         * Load all the data required for the page.
         *
         * @returns {Promise<void>}
         */
        async loadData() {
            const t = this

            t.isLoading = true

            await Promise.all([t.loadFullCurrentUserData()])

            t.isLoading = false
        },

        /**
         * Load Full Current User Data
         *
         * Load the current user's full data.
         *  - User data
         *  - File data (profile picture)
         *
         * @returns {Promise<void>}
         */
        async loadFullCurrentUserData() {
            const t = this
            const CURRENT_USER_AUTH = t.currentUserAuth

            const RESPONSE = await t.MIX_redis_getFullUserData(CURRENT_USER_AUTH.uid)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error Loading Current User data: ', RESPONSE.errors)
                return
            }

            t.fullCurrentUserData = RESPONSE.data
        }
    },

    async created() {
        const t = this

        await t.loadData()
    }
}
</script>

<style scoped>
.sideMenu-item {
    transition: 0.25s;
}

.sideMenu-item:hover {
    background: #222222;
}
</style>
