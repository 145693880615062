import Vue from 'vue'
import VueRouter from 'vue-router'
import {getAuth} from "firebase/auth";
import appConfig from "../appConfig.json";

// Route Components
import Accreditation from "@/views/accreditation/staff/Accreditation.vue"
import AccreditationMedia from "@/views/mediaAccreditation/staff/MediaAccreditation.vue"
import MyAccreditation from "@/views/accreditation/organisation/MyAccreditation.vue"
import AppSupport from '@/views/appSupport/AppSupport.vue'
import EventDayDashboard from '@/views/eventDayDashboard/EventDayDashboard.vue'
import Events from '@/views/events/Events.vue'
import FireRollCall from "@/views/fireRollCall/FireRollCall.vue"
import Home from '@/views/home/Home.vue'
import MassEmails from "@/views/massEmails/MassEmails.vue"
import MyOrganisation from "@/views/organisations/organisation/MyOrganisation.vue"
import MyProfile from '@/views/users/myProfile/MyProfile.vue'
import NoticeBoard from '@/views/noticeBoard/NoticeBoard.vue'
import Organisations from '@/views/organisations/staff/Organisations.vue'
import Parking from '@/views/parking/Parking.vue'
import Payroll from '@/views/payroll/PayrollMainPage.vue'
import PreEventDashboard from '@/views/preEventDashboard/PreEventDashboard.vue'
import Qualifications from '@/views/qualifications/Qualifications.vue'
import Register from '@/views/auth/Register.vue'
import Reporting from '@/views/reporting/Reporting.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import Sites from '@/views/sites/Sites.vue'
import SignIn from '@/views/auth/SignIn.vue'
import Swapp from "@/views/swapp/Swapp.vue"
import SwappTasks from "@/views/swappTasks/SwappTasks.vue"
import Teams from '@/views/teams/Teams.vue'
import Unauthorised from '@/views/unauthorised/Unauthorised.vue'
import Users from '@/views/users/Users.vue'
import ToDoList from '@/views/toDoList/ToDoList.vue'
import DeveloperPage from '@/views/developerPage/DeveloperPage.vue'

import SwappHistory from "@/views/swappHistory/swappHistory.vue";

Vue.use(VueRouter)

const routes = [
	// Auth
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	{
		path: '/signIn',
		name: 'SignIn',
		component: SignIn
	},
	{
		path: '/resetPassword',
		name: 'ResetPassword',
		component: ResetPassword
	},

	// App
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-Supervisor', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User', 'Organisation-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/accreditation',
		name: 'Accreditation',
		component: Accreditation,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/accreditationMedia',
		name: 'AccreditationMedia',
		component: AccreditationMedia,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/developerPage',
		name: 'Developer Page',
		component: DeveloperPage,
		meta: {
			requiresAuth: true,
			requiresDeveloperAccess: true, // New meta field for developer access
			userStatus: ['Approved'],
			userName: ['Vindico Developer'] // Restrict access to 'Vindico Developer'
		}
	},
	{
		path: '/myAccreditation',
		name: 'MyAccreditation',
		component: MyAccreditation,
		meta: {
			requiresAuth: true,
			userLevel: ['Organisation-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/appSupport',
		name: 'AppSupport',
		component: AppSupport,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-Supervisor', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User', 'Organisation-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/eventDayDashboard',
		name: 'EventDayDashboard',
		component: EventDayDashboard,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-User', 'Steward-Management'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/events',
		name: 'Events',
		component: Events,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/fireRollCall',
		name: 'Fire Roll Call',
		component: FireRollCall,
		meta: {
			requiresAbility: 'Fire Roll Call',
			requiresAuth: true,
			userLevel: ['Staff-Admin'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/massEmails',
		name: 'Mass Emails',
		component: MassEmails,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/myOrganisation',
		name: 'My Organisation',
		component: MyOrganisation,
		meta: {
			requiresAuth: true,
			userLevel: ['Organisation-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/myProfile',
		name: 'My Profile',
		component: MyProfile,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-Supervisor', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User', 'Organisation-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/noticeBoard',
		name: 'Notice Board',
		component: NoticeBoard,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/organisations',
		name: 'Organisations',
		component: Organisations,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/parking',
		name: 'Parking',
		component: Parking,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User', 'Organisation-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/payroll',
		name: 'Payroll',
		component: Payroll,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/preEventDashboard',
		name: 'PreEventDashboard',
		component: PreEventDashboard,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-User', 'Steward-Management'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/qualifications',
		name: 'Qualifications',
		component: Qualifications,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/reporting',
		name: 'Reporting',
		component: Reporting,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-Supervisor', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User', 'Organisation-Manager'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/sites',
		name: 'Sites',
		component: Sites,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-Supervisor', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User'],
			userStatus: ['Approved']
		}
	},
	{
		name: 'Swapp',
		path: '/swapp',
		component: Swapp,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-Supervisor', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/swappTasks/:code?',
		name: 'SWAPP Tasks',
		component: SwappTasks,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-Supervisor', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/teams',
		name: 'Teams',
		component: Teams,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/unauthorised',
		name: 'Unauthorised',
		component: Unauthorised,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-Supervisor', 'Staff-User', 'Steward-Supervisor', 'Steward-Management', 'Steward-Deputy', 'Steward-User', 'Organisation-Manager'],
			userStatus: ['Pending', 'Rejected', 'Suspended', 'Archived'],
		}
	},
	{
		path: '/users',
		name: 'Users',
		component: Users,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Manager', 'Staff-User'],
			userStatus: ['Approved']
		}
	},
	{
		path: '/toDoList',
		name: 'ToDoList',
		component: ToDoList,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-User', 'Steward-Supervisor', 'Steward-Deputy', 'Steward-User'],
			userStatus: ['Approved']
		}
	},

    {
        path: '/swappHistory',
        name: 'SWAPP History', 
        component: SwappHistory,
        meta: {
            requiresAuth: true,
            userLevel: ['Staff-Admin', 'Staff-Manager', 'Organisation-Manager'],
            userStatus: ['Approved']
        }
    },

	// Fallback to Home page for unknown routes
	{
		path: '*',
		name: 'Home',
		component: Home,
		meta: {requiresAuth: true}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const REQUIRES_AUTH = to.matched.some(record => record.meta.requiresAuth)
	const requiresDeveloperAccess = to.matched.some(record => record.meta.requiresDeveloperAccess)
	let currentUserData = {}

	navigator.serviceWorker.register('/service-worker.js')
		.then(reg => reg.update())

	// If the route does not require authorisation, continue
	if (!REQUIRES_AUTH) next()

	// If the route does require authorisation
	if (REQUIRES_AUTH) {

		const CURRENT_USER_AUTH = getAuth().currentUser
		if (CURRENT_USER_AUTH) currentUserData = JSON.parse(localStorage.getItem('currentUserData'))

		// If the route is not included in the allowedRoutes, redirect them to the Home page
		const isRouteAllowed = appConfig.allowedRoutes.some(route => route.name === to.name)
		if (!isRouteAllowed) return next({ path: '/' })

		// If the user is not logged in, redirect them to the login page
		if (!CURRENT_USER_AUTH) return next({path: '/signIn'})

		if (requiresDeveloperAccess && currentUserData.userName !== 'Vindico Developer') return next({path: '/'})

		// If the user's userLevel is not included in the meta.userLevel, redirect them to the Home page
		if (to.meta.userLevel && !to.meta.userLevel.includes(currentUserData.userLevel)) return next({path: '/'})

		// If the user's userStatus (meta.userStatus) is not 'Approved', redirect them to ''unauthorised.vue
		if (to.meta.userStatus && !to.meta.userStatus.includes(currentUserData.userStatus)) return next({path: '/unauthorised'})

		// If all is good
		else next()
	}

})

export default router
