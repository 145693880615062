import {version, name as appName} from '../package.json';
import {getAuth} from "firebase/auth";
import imageCompression from 'browser-image-compression';
import appConfig from '../appConfig.json';
import parsePhoneNumber from 'libphonenumber-js';
import parsePhoneNumberFromString from "libphonenumber-js";

const mixin = {

	data: () => ({
		appVersion: version,
		appName: appName,
		currentUserAuth: getAuth().currentUser,

		// App options -------------------------------------------------------------------------------------------------
		accidentTypeOptions: ['Accident', 'Injury', 'Near Miss'],

		eventGroupOptions: [
			'Autumn Nations Series',
			'Club Football',
			'Club Rugby',
			'Concert',
			'Entertainment',
			'Family',
			'FAW',
			'Motorsport',
			'Six Nations',
		],
		eventTypeOptions: [
			'Concert',
			'Community Event',
			'Entertainment',
			'Football',
			'Motorsport',
			'Private Function',
			'Rugby Union',
			'Sporting Event',
		],

		noticeBoardPriorityOptions: ['Low', 'Medium', 'High'],
		noticeBoardVisibilityOptions: ['Hidden', 'Visible'],

		observationTypesOptions: ['Accident Report', 'Event Accident Report', 'Post Event Report'],
		observationPriorityOptions: ['High', 'Medium', 'Low'],
		observationStatusOptions: ['Pending', 'In Progress', 'On Hold', 'Resolved'],

		richTextEditorOptions: [
			['bold', 'italic', 'underline', 'strike'],
			[{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
			[{list: 'ordered'}, {list: 'bullet'}],
			[{indent: '-1'}, {indent: '+1'}],
			[{color: []}, {background: []}],
			['clean'],
			["image", "code-block"]
		],

		swappTasksUserIssuesOptions: [
			'Unable to gain access',
		],
		swappTasksSupervisorIssuesOptions: [
			'Body Fat and Scale',
			'Consumables (Shower Gel and Toilet Paper)',
			'Uric Acid Staining on Urinals and Ext Toilet Basins',
			'Dust',
			'Debris',
			'Smears',
			'Bins Full',
			'Staining',
			'Internal/External Lockers Dirty',
			'Consumables (Hand Towel, Blue Roll, Hand Soap)',
			'Towel Bins',
			'Litter'
		],
        userAccessAndAbilitiesOptions: [
            'Visitor-Contactable'
        ],
		userObservationResponsibilitiesOptions: ['All Observations'],
		userPayGradeOptions: [
			'Group Staff',
			'Fire Controller',
			'Deputy Fire Controller',
			'Chief Steward',
			'Senior Deputy Chief Steward',
			'Deputy Chief Steward',
			'Senior Supervisor',
			'Fire Steward',
			'Supervisor',
			'Deputy Supervisor',
			'Steward'
		],
		userStatusOptions: ['Pending', 'Approved', 'Archived', 'Rejected', 'Suspended'],
		userTypeOptions: ['Organisation', 'Staff', 'Steward', 'Visitor'],

		fireRollCallTypeOptions: ['Drill', 'Incident'],
	}),

	methods: {

		/**
		 * Compress Image
		 *
		 * Compress and return image ready for uploading.
		 *
		 * @param image the file to compress
		 * @param maxSizeMB maximum image size in MB (defaults to 1)
		 * @param maxWidthOrHeight maximum image size in px (defaults to 960)
		 * @returns {File} the compressed file if successful, or the original image if not
		 */
		async MIX_compressImage(image, maxSizeMB, maxWidthOrHeight) {

			// Set options for compression
			const options = {
				maxSizeMB: maxSizeMB || 1,
				maxWidthOrHeight: maxWidthOrHeight || 480,
				useWebWorker: true,
			}

			try {
				const compressedImage = await imageCompression(image, options)
				return {
					data: compressedImage,
					hasErrors: false,
					error: null,
				}
			} catch (error) {
				console.error('compression error: ', error)
				return {
					data: image,
					hasErrors: true,
					error: error,
				}

			}

		},

		/**
		 * Export Documents
		 *
		 * This function exports data into a CSV format and triggers a download of the generated CSV.
		 *
		 * @param headers {object} - The headers for the CSV file, each key-value pair represents a column
		 * @param fileTitle {string} - The name of the CSV file
		 * @param data {array} - An array of objects, each object should have keys matching the headers, representing a row in the CSV
		 */
		MIX_exportDocuments(headers, fileTitle, data) {
			const t = this

			// Prepare the CSV content
			const CSV_CONTENT = [
				Object.values(headers),
				...data.map(item => Object.values(item))
			]
				.map(row => row.join(','))
				.join('\r\n')

			try {

				// Create a blob for the CSV content
				const BLOB = new Blob([CSV_CONTENT], {type: "text/csv;charset=utf-8;"})

				// For MS browsers, use the msSaveBlob method
				if (navigator.msSaveBlob) {
					navigator.msSaveBlob(BLOB, `${fileTitle}.csv`)
					return
				}

				// For non-MS browsers, create a link, trigger a click to start download, then remove the link
				const LINK = document.createElement('a')
				LINK.href = URL.createObjectURL(BLOB)
				LINK.download = `${fileTitle}.csv`
				LINK.style.visibility = "hidden"
				document.body.appendChild(LINK)
				LINK.click()
				document.body.removeChild(LINK)

			} catch (error) {
				console.error('Error exporting Teams CSV: ', error)
				t.$sharedState.errorMessage = 'There was a problem exporting Team to a CSV file, please try again.'
				return
			}
		},

		/**
		 * Extract Image Data From Url
		 *
		 * Extract the image data from a Firebase Storage URL.
		 * Return an object containing the image data to save to the Files collection.
		 *
		 * @param url {string} - the Firebase Storage URL
		 * @returns {{FOLDER_NAME: string, IMAGE_NAME: string, TOKEN: string}}
		 */
		MIX_extractImageDataFromUrl(url) {
			const t = this
			const DOWNLOAD_URL = url

			// Extract the image base URL
			const BASE_URL_START_INDEX = DOWNLOAD_URL.indexOf('https://')
			const BASE_URL_END_INDEX = DOWNLOAD_URL.indexOf('.com/') + 5
			const IMAGE_BASE_URL = DOWNLOAD_URL.substring(BASE_URL_START_INDEX, BASE_URL_END_INDEX)

			// Extract the storage bucket
			const BUCKET_START_INDEX = BASE_URL_END_INDEX
			const BUCKET_END_INDEX = DOWNLOAD_URL.indexOf('/o/')
			const STORAGE_BUCKET = DOWNLOAD_URL.substring(BUCKET_START_INDEX, BUCKET_END_INDEX)

			// Extract the folder name
			const FOLDER_NAME_START_INDEX = DOWNLOAD_URL.lastIndexOf('/') + 1
			const FOLDER_NAME_END_INDEX = DOWNLOAD_URL.lastIndexOf('%2F')
			const FOLDER_NAME = DOWNLOAD_URL.substring(FOLDER_NAME_START_INDEX, FOLDER_NAME_END_INDEX)

			// Extract the image name
			const IMAGE_NAME_START_INDEX = FOLDER_NAME_END_INDEX + 3
			const IMAGE_NAME_END_INDEX = DOWNLOAD_URL.indexOf('?alt=media')
			const IMAGE_NAME = DOWNLOAD_URL.substring(IMAGE_NAME_START_INDEX, IMAGE_NAME_END_INDEX)

			// Extract the image token
			const TOKEN_START_INDEX = DOWNLOAD_URL.indexOf('&token=') + 7
			const TOKEN = DOWNLOAD_URL.substring(TOKEN_START_INDEX)

			return {FOLDER_NAME, IMAGE_NAME, TOKEN}
		},

		/**
		 * Get Image Path
		 *
		 * Create and return the full storage path for the given image.
		 * The token is a Firebase media token used to access the image.
		 *
		 * @param folderName {string} the name of the folder the image is in
		 * @param imageName {string} the name of the image
		 * @param token {string} the token for the image
		 * @returns {string} the full storage path for the image
		 */
		MIX_getImagePath(folderName, imageName, token) {
			const STORAGE_BASE_URL = process.env.VUE_APP_STORAGE_BASE_URL
			const STORAGE_BUCKET_NAME = process.env.VUE_APP_STORAGE_BUCKET_NAME

			return `${STORAGE_BASE_URL}/${STORAGE_BUCKET_NAME}/o/${folderName}%2F${imageName}?alt=media&token=${token}`
		},

		/**
		 * MIX Go
		 *
		 * Navigate to given path
		 *
		 * @param path the path of the page to navigate to
		 * @param paramData
		 */
		MIX_go(path, paramData) {
			const t = this

			t.$router.push({path, params: {data: paramData}})
				.catch(error => {
					if (error.name !== 'NavigationDuplicated') console.error('Routing error: ', error)
				})

		},

		/**
		 * SWAPP User
		 *
		 * Take the User, and SWAPP data, and SWAPP the User by updating their User document.
		 * If the Site, Location, or Status are missing, throw and error and return.
		 *
		 * @param userData - the User's data to update
		 * @param siteId - the ID of the Site for SWAPPing
		 * @param locationId - the ID of the Location for SWAPPing
		 * @param swappMethod - the methods user for SWAPPing
		 * @param newSwappStatus - the new SWAPP Status
		 * @returns {Promise<{hasErrors}|axios.AxiosResponse<*>|void|{hasErrors: boolean, data: null, error: string}>}
		 */
		async MIX_swappUser(userData, siteId, locationId, swappMethod, newSwappStatus) {
			const t = this

			// If any of the required details are missing, throw an error
			if (!siteId || !locationId || !['In', 'Out'].includes(newSwappStatus)) {
				t.$sharedState.errorMessage = 'Something went wrong when trying to SWAPP, Please try again.'
				return {
					data: null,
					hasErrors: true,
					error: 'Something went wrong when trying to SWAPP, Please try again.'
				}
			}

			// Set the SWAPP data to the User
			userData.userLastSwappSiteId = siteId
			userData.userLastSwappLocationId = locationId
			userData.userLastSwappMethod = swappMethod
			userData.userSwappStatus = newSwappStatus
			userData.userLastSwappDateTime = new Date().getTime()

			const RESPONSE = await t.MIX_redis_updateUserSwappData(userData)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating User SWAPP data: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'Something went wrong when trying to SWAPP, Please try again.'
				return
			}

			return RESPONSE
		},

		/**
		 * Is Feature Allowed
		 *
		 * Check if the feature is allowed for the current version of the app.
		 * Check if the routes is present in appConfig > allowedRoutes > name === featureName.
		 *
		 * @param featureName {string} - The name of the feature to check.
		 * @returns {boolean} - True if the feature is allowed, false if not.
		 */
		MIX_isFeatureAllowed(featureName) {
			return appConfig.allowedRoutes.some(route => route.name === featureName)
		},

		/**
		 * Formatting --------------------------------------------------------------------------------------------------
		 */

		/**
		 * Format Capitalise First Letters
		 *
		 * Capitalise the first letters of all words in a string.
		 *
		 * @param string - the string of which to capitalise the words
		 * @returns {string} - the formatted, capitalised worded string
		 */
		MIX_formatCapitaliseFirstLetters(string) {
			return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
		},

		/**
		 * Format Date
		 *
		 * Format and return the date to a readable format.
		 *
		 * @param {number} epochTimestamp - The timestamp in epoch time
		 * @param {string} formatLength - The required format length for the date (long|short|numeric)
		 * @return {string} The formatted date string
		 */
		MIX_formatDate(epochTimestamp, formatLength) {
			let suffix

			// Create a new Date object using the provided timestamp
			let DATE = new Date(epochTimestamp)

			// Get the full year from the date
			let YEAR = DATE.getFullYear()

			// Get the month name from the date object
			let MONTH_NAME = DATE.toLocaleString("default", {month: formatLength})

			// Get the day of the month from the date
			let DAY = DATE.getDate()

			// Get the day of the week from the date, or an empty string if formatLength is 'numeric'
			let DAY_OF_WEEK = formatLength !== 'numeric'
				? DATE.toLocaleString("default", {weekday: formatLength}) + ','
				: ''

			// Check if the day is between 11 and 13, if it is, set suffix to 'th'
			if (DAY >= 11 && DAY <= 13) {
				suffix = "th"
			} else {
				// Check the last digit of the day and set its suffix
				switch (DAY % 10) {
					case 1:
						suffix = "st"
						break
					case 2:
						suffix = "nd"
						break
					case 3:
						suffix = "rd"
						break
					default:
						suffix = "th"
				}
			}

			// Return the date format relevant to the formatLength
			if (formatLength === 'long') return `${DAY_OF_WEEK} ${DAY}${suffix} ${MONTH_NAME} ${YEAR}`
			if (formatLength === 'short') return `${DAY}${suffix} ${MONTH_NAME} ${YEAR}`
			if (formatLength === 'numeric') return `${DAY}/${MONTH_NAME}/${YEAR}`
		},

		/**
		 * Format Telephone Number
		 *
		 * Format landlines (01|02|03) into a proper format, leave mobiles as they are.
		 *
		 * @param {string} telephone - The telephone number
		 * @return {string} The formatted telephone number
		 */
		MIX_formatTelephoneNumber(telephone) {
			let formattedTelephone = ''

			// If the telephone number starts with '01', '02', '03'
			if (telephone.startsWith('01') || telephone.startsWith('02') || telephone.startsWith('03')) {

				// Format the telephone number (11 digit is optional)
				formattedTelephone = `(${telephone[0]}${telephone[1]}${telephone[2]}) ${telephone[3]}${telephone[4]}${telephone[5]}${telephone[6]} ${telephone[7]}${telephone[8]}${telephone[9]}${telephone.length === 11 ? telephone[10] : ''}`
			}

			// If the telephone number doesn't start with '01', '02', or '03'
			else {

				// Don't format it
				formattedTelephone = telephone
			}

			return formattedTelephone
		},

		/**
		 * Format Time To HH MM
		 *
		 * Format the start and finish times into an hours and minutes format.
		 * The returned format's minutes are conditionally rendered on omitting the value 0, e.g...
		 *  - 10 hrs 30 mins
		 *  - 10 hrs
		 *
		 * @param {Date} startTime - The start time string to use, e.g. "10:00"
		 * @param {Date} finishTime - The finish time string to use, e.g. "18:00"
		 * @return {{displayTime: string, decimalTime: number}} The formatted time as "[x] hrs [y] mins"
		 */
		MIX_formatTimeToHHMM(startTime, finishTime) {

			// Create some dummy dates to use for the calculations
			startTime = new Date("1970-01-01T" + startTime + ":00.000Z")
			finishTime = new Date("1970-01-01T" + finishTime + ":00.000Z")

			let timeInHours = (finishTime - startTime) / (1000 * 60 * 60)
			let timeInMinutes = Math.floor((timeInHours * 60) % 60)
			let remainingHours = Math.floor(timeInHours)


			return {
				decimalTime: timeInHours,
				displayTime: remainingHours + " hrs " + (timeInMinutes === 0 ? '' : timeInMinutes + " mins")
			}
		},

		/**
		 * Format User Internal ID Number
		 *
		 * Format a user's internal ID number (if applicable).
		 * The ID number should be in a S00000 format, padded with 0s between the S and the number.
		 * E.g... 1 = S00001, 01 = S00001, S1 = S00001 etc
		 *
		 * @param stewardNumber - the number to format
		 * @returns {string} - the formatted string
		 */
		MIX_formatUserInternalIdNumber(stewardNumber) {
			let formattedStewardNumber = stewardNumber.replace(/^[S|s]/i, '').padStart(5, '0')

			return 'S' + formattedStewardNumber
		},

		/**
		 * Format DateTime To Time
		 *
		 * Format an epoch dateTime (in milliseconds) to a formatted time string (HH:MM)
		 *
		 * @param dateTime - the dateTime to format
		 * @returns {string} - the formatted time string
		 */
		MIX_formatDateTimeToTime(dateTime) {
			return new Date(dateTime).toLocaleTimeString('en-GB', {hour: 'numeric', minute: 'numeric'})
		},

		/**
		 * Local Storage -----------------------------------------------------------------------------------------------
		 */

		/**
		 * Add To Local Storage
		 *
		 * Save the data to local storage using the given tag.
		 * The site/location data is saved to local storage so the device is always ready with minimum config requirements.
		 *
		 * @param tag the tag to save the data with
		 * @param data the data to save
		 */
		MIX_addToLocalStorage(tag, data) {
			localStorage.setItem(tag, JSON.stringify(data))
		},

		/**
		 * Get From Local Storage
		 *
		 * Get the saved data from local storage using the given tag.
		 *
		 * @param tag the tag used to fetch the data
		 * @returns {any} the data as a JSON
		 */
		MIX_getFromLocalStorage(tag) {
			return JSON.parse(localStorage.getItem(tag))
		},

		/**
		 * Delete from Local Storage
		 *
		 * Remove an item from local storage by its tag name.
		 *
		 * @param tag the tag to delete
		 */
		MIX_deleteFromLocalStorage(tag) {
			localStorage.removeItem(tag)
		},

		/**
		 * Get Current User
		 *
		 * Return the currently signed-in User data, if set.
		 *
		 * @returns {*|{}} - JSON object containing the currently signed-in User's data
		 */
		MIX_getCurrentUser() {
			const t = this

			return t.MIX_getFromLocalStorage('currentUserData') || {}
		},

		/**
		 * Validation --------------------------------------------------------------------------------------------------
		 */

		/**
		 * Is Email Valid
		 *
		 * Validates common email addresses.
		 *
		 * ^ start of string
		 * [a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+` : Match one or more of the characters within the square brackets (these are characters allowed in the local-part of email address before @)
		 * @ : the 'at' symbol
		 * [a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])? : Matches the domain name before the TLD ( Top-level domain)
		 * (?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)* : Matches the TLD and subdomains(if any)
		 * $ end of string
		 *
		 * @param {string} email The email to be validated
		 * @return {boolean} true if is valid, false otherwise
		 */
		MIX_isEmailValid(email) {
			const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+/

			return EMAIL_REGEX.test(email)
		},

		/**
		 * Is LatLng Valid
		 *
		 * Validates coordinates in a Latitude and Longitude decimal format.
		 *
		 * @param {string} coords The coordinates to be validated
		 * @return {{isLngValid: boolean, isLatValid: boolean}} true if is valid, false otherwise
		 */
		MIX_isLatLngValid(coords) {
			let isLatValid
			let isLngValid

			isLatValid = coords.latitude >= -90 && coords.latitude <= 90
			isLngValid = coords.longitude >= -180 && coords.longitude <= 180

			return {isLatValid, isLngValid}
		},

		/**
		 * Is Password Valid
		 *
		 * Validates a password.
		 *
		 * Must be at least 8 characters.
		 * Must contain at least 1 uppercase.
		 * Must contain at least 1 lowercase.
		 * Must contain at least 1 number.
		 * Must contain at least 1 special character from ? # @ ! £ $ % &
		 *
		 * @param {string} password The password to be validated
		 * @return {boolean} true if is valid, false otherwise
		 */
		MIX_isPasswordValid(password) {
			const PASSWORD_REGEX = /(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[?#@!£$%&]).{8,}/

			return PASSWORD_REGEX.test(password)
		},

		/**
		 * Is Postcode Valid
		 *
		 * Validates a UK postcode.
		 *
		 * @param {string} postcode The postcode to be validated
		 * @return {boolean} true if is valid, false otherwise
		 */
		MIX_isPostcodeValid(postcode) {
			const POSTCODE_REGEX = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i

			return POSTCODE_REGEX.test(postcode)
		},

		/**
		 * Is Telephone Number Valid
		 *
		 * Validates telephone numbers from around the world.
		 * Sanitises the number by removing whitespace characters.
		 * If no international dialing code is present with the number, it'll be parsed as a UK number.
		 *
		 * @param {string} telephoneNumber The telephone number to be validated
		 * @return {string || boolean} number if is valid, false otherwise
		 */
		MIX_isTelephoneNumberValid(telephoneNumber) {

			// Remove whitespace characters from the input telephone number
			const SANITISED_NUMBER = telephoneNumber.replace(/\s+/g, '')

			// Parse and validate the cleaned phone number
			const PHONE_NUMBER = parsePhoneNumberFromString(SANITISED_NUMBER, 'GB')

			// Return the number if it is valid, false otherwise
			return PHONE_NUMBER?.isValid() ? PHONE_NUMBER.number : false
		},

		/**
		 * Is User Internal ID Number Valid
		 *
		 * Validates the User's Internal ID Number.
		 * Must be in the format S00000.
		 * The leading 'S' is optional.
		 *
		 * @param idNumber - the ID number to validate
		 * @returns {boolean} - true if it is valid, false otherwise
		 */
		MIX_isUserInternalIdNumberValid(idNumber) {
			const STEWARD_NUMBER_REGEX = /^[S|s]?\d{1,5}$/

			return STEWARD_NUMBER_REGEX.test(idNumber)
		}

	}

}

export default {
	install(Vue) {
		Vue.mixin(mixin)
	}
}
