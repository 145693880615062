<template>
    <div>
        <page-title info="Manage your parking spaces and events" :divider="true" title="Parking" />

        <!--Action Bar-->
        <div class="d-flex align-center mt-4">
            <!--Search-->
            <app-form-field
                form-type="textInput"
                append-icon="icons8-search"
                class="mr-4"
                :clearable="true"
                label="Event Name"
                style="width: 100%"
                v-model.trim="searchByEventName"
            />

            <!--Add Button-->
            <app-btn @click.native="openRightPanel" class="mr-4" icon="add" label="Add" />

            <!--Filter Button - with a numbered badge-->
            <div style="position: relative">
                <app-btn @click.native="toggleFiltersVisibility" class="mr-4" color="appWhite" icon="filter" icon-color="primary" />
                <span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
            </div>

            <!--More Actions Menu-->
            <more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption" />
        </div>

        <!--Clear Filters-->
        <div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
            <app-btn @click.native="clearFilters" icon="close" label="Clear Filters" />
        </div>

        <!--Table-->
        <v-data-table v-if="$vuetify.breakpoint.width >= 600" class="appWhite rounded-lg mt-4" :headers="computedHeaders" :items="computedTableData">
            <!--ID-->
            <template v-slot:item.entityId="{ item }">
                <app-text size="small">{{ item.entityId }}</app-text>
            </template>

            <!--Event-->
            <template v-slot:item.parkingSpaceEvent="{ item }">
                <app-text size="small">{{ item.eventData?.eventName }}</app-text>
            </template>

            <!--Organisation/Individual Name-->
            <template v-slot:item.parkingSpaceOrganisation="{ item }">
                <!--Organisation-->
                <div v-if="item.organisationData?.entityId">
                    <app-text size="small">{{ item.organisationData?.organisationName }}</app-text>
                    <app-text color="grey9" size="small">Organisation</app-text>
                </div>

                <!--Individual-->
                <div v-if="item.individualData?.parkingSpaceIndividualName">
                    <app-text size="small">{{ item.individualData.parkingSpaceIndividualName }}</app-text>
                    <app-text color="grey9" size="small">Individual</app-text>
                </div>

                <!--Reserved-->
                <div v-if="item.reservedData?.length">
                    <app-text color="grey9" size="small">Reservations</app-text>
                </div>
            </template>

            <!--Car Parks-->
            <template v-slot:item.parkingSpaceCarPark="{ item }">
                <app-text v-for="item in item.parkingData" :key="item.carParkData?.entityId" size="small">
                    {{ item.carParkData.carParkName }}
                </app-text>
            </template>

            <!--Total Spaces-->
            <template v-slot:item.parkingSpaceTotal="{ item }">
                <div class="d-flex align-center justify-center">
                    <!--Line Totals-->
                    <div class="d-flex flex-column">
                        <div v-for="item in item.parkingData" :key="item.parkingSpaceData?.entityId" class="d-flex align-center">
                            <app-text size="small">
                                {{ getNumberOfSpacesRequired(item.parkingSpaceData).regularSpaces }}
                            </app-text>
                            <v-divider vertical class="mx-2" />
                            <app-text size="small">
                                {{ getNumberOfSpacesRequired(item.parkingSpaceData).disabledSpaces }}
                            </app-text>
                            <v-divider vertical class="mx-2" />
                        </div>
                    </div>

                    <!--Sum of Totals-->
                    <app-text size="small-bold">
                        {{ getTotalNumberOfSpacesRequired(item.parkingData) }}
                    </app-text>
                </div>
            </template>

            <!--Action Button-->
            <template v-slot:item.action="{ item }">
                <app-icon @click.native="openRightPanel(item)" class="cursorPointer" color="primary" icon="arrowForward" />
            </template>
        </v-data-table>

        <!--Mobile Cards-->
        <div v-if="$vuetify.breakpoint.width < 600">
            <!--No data message-->
            <app-text v-if="!computedTableData.length" class="text-center mt-4" color="grey9">You have no Event Parking to view </app-text>

            <event-parking-mobile-card v-for="item in computedTableData" :key="item.id" @click.native="openRightPanel(item)" :cardData="item" />
        </div>

        <!--Filters Panel ------------------------------------------------------------------------------------------ -->
        <filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
            <div class="pa-4">
                <!--Car Park-->
                <page-break-title title="Car Parks" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :deletable-chips="true"
                        :items="carParksData"
                        item-text="carParkName"
                        label="Car Parks"
                        :multiple="true"
                        :small-chips="true"
                        v-model="filterByCarParks"
                    />
                </div>

                <!--Parking Space Organisation-->
                <page-break-title class="mt-8" description="Individual's Name | Organisation's Name | Name on Reserved List" title="Attendee" />
                <div class="mt-4">
                    <app-form-field form-type="textInput" :clearable="true" label="Attendee's Name" v-model.trim="filterByAttendee" />
                </div>

                <!--Spaces Required-->
                <page-break-title class="mt-8" title="Spaces Required" />
                <div class="mt-4">
                    <app-form-field form-type="textInput" :clearable="true" label="Spaces Required" v-model.trim="filterBySpacesRequired" />
                </div>
            </div>
        </filter-panel>

        <!--Right Side ----------------------------------------------------------------------------------- Right Side-->
        <transition
            enter-active-class="animate__animated animate__fadeInRight animate__faster"
            leave-active-class="animate__animated animate__fadeOutRight animate__faster"
            mode="out-in"
        >
            <div
                v-if="isRightPanelVisible"
                class="appGrey formShadow"
                style="position: absolute; top: 0; bottom: 0; right: 0; overflow: auto"
                :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
                v-model="isRightPanelVisible"
            >
                <!--Header-->
                <div class="d-flex align-center primary pa-4">
                    <!--No Name-->
                    <v-spacer />
                    <app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close" size="32" />
                </div>

                <!--Action Bar-->
                <div class="d-flex align-center pa-4">
                    <v-spacer />

                    <!--Send QR Code Button-->
                    <app-btn
                        v-if="selectedItem.parkingData?.length"
                        @click.native="openSendQrCodeDialog"
                        color="primary"
                        icon="qrCode"
                        label="Send QR Code"
                    />

                    <!--Action Buttons-->
                    <edit-icon v-if="tabs === 'form'" @click.native="editItem" :isActive="!isReadOnly" />
                </div>

                <!--Tabs-->
                <v-tabs v-model="tabs">
                    <!--Form-->
                    <v-tab v-if="shouldFormBeVisible" href="#form">
                        <app-text size="small">Form</app-text>
                    </v-tab>

                    <!--Current-->
                    <v-tab v-if="selectedItem.parkingData?.length" @click="shouldFormBeVisible = false" href="#current">
                        <app-text size="small">Current</app-text>
                    </v-tab>
                </v-tabs>

                <!--Tabs Content-->
                <v-tabs-items class="appGrey" v-model="tabs">
                    <!--Form-->
                    <v-tab-item value="form">
                        <event-parking-form
                            v-if="selectedParkingType === 'Event Parking'"
                            @emitReloadPage="emittedReloadPage"
                            class="pa-4"
                            :carParksData="carParksData"
                            :eventsData="eventsData"
                            :formData="selectedParkingItem"
                            :isReadOnly="isReadOnly"
                            :organisationsData="organisationsData"
                        />
                    </v-tab-item>

                    <!--Current Parking-->
                    <v-tab-item value="current">
                        <event-parking-existing
                            @emitHandleSelectedParkingSpace="emittedHandleSelectedParkingSpace"
                            @emitReloadPage="emittedReloadPage"
                            :fullParkingData="selectedItem"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </transition>

        <!--Dialogs ------------------------------------------------------------------------------------------------ -->
        <app-dialog :is-visible="isSendQrCodeDialogVisible" @emitCloseSendQrCodeDialog="emittedCloseSendQrCodeDialog">
            <div class="appGrey pa-4">
                <app-text>
                    You are about to send this QR Code to the manager of this Organisation so they can distribute it internally.
                    <br />
                    <br />
                    On the day, attendees can have their QR Code scanned and be directed to the correct parking space.
                    <br />
                    <br />
                    <span class="font-weight-bold">Do you want to send this now?</span>
                </app-text>
                <v-divider class="my-4" />
                <div class="d-flex justify-space-between">
                    <!--Cancel-->
                    <app-btn @click.native="closeSendQrCodeDialog" color="grey" icon="cancel" label="Cancel" />

                    <!--Yes-->
                    <app-btn @click.native="sendQrCode" color="green" icon="success" label="Yes, send" />
                </div>
            </div>
        </app-dialog>
    </div>
</template>

<script>
import ParkingCarParkForm from '@/views/parking/admin/parkingCarParks/parkingCarParkForm/ParkingCarParkForm.vue'
import EventParkingForm from '@/views/parking/admin/parkingSpaces/eventParking/eventParkingForm/EventParkingForm.vue'
import EventParkingMobileCard from '@/views/parking/admin/parkingSpaces/eventParking/eventParkingMobileCard/EventParkingMobileCard.vue'
import EventParkingExisting from '@/views/parking/admin/parkingSpaces/eventParking/eventParkingExisting/EventParkingExisting.vue'

export default {
    name: 'ParkingSpacesPage',

    components: { EventParkingExisting, EventParkingMobileCard, EventParkingForm, ParkingCarParkForm },

    data: () => ({
        filterByAttendee: '',
        filterByCarParks: [],
        filterBySpacesRequired: '',
        isFiltersPanelVisible: false,
        isLoading: true,
        isReadOnly: false,
        isRightPanelVisible: false,
        isSendQrCodeDialogVisible: false,
        moreActionsMenuOption: [{ name: 'Event Parking', icon: 'car' }],
        searchByEventName: '',
        selectedItem: {},
        tableHeaders: [
            { text: 'ID', value: 'entityId', align: 'left', sortable: false, hidden: true },
            { text: 'Event', value: 'parkingSpaceEvent', align: 'left', sortable: false, hidden: false },
            { text: 'Attendee Name', value: 'parkingSpaceOrganisation', align: 'left', sortable: false, hidden: false },
            { text: 'Car Park(s)', value: 'parkingSpaceCarPark', align: 'left', sortable: false, hidden: false },
            {
                text: 'Spaces (Regular|Accessible|Total)',
                value: 'parkingSpaceTotal',
                align: 'center',
                sortable: false,
                hidden: false
            },
            { text: '', value: 'action', align: 'right', sortable: false, width: '48px' }
        ],
        tabs: '',

        selectedParkingType: '',
        selectedParkingItem: {},
        shouldFormBeVisible: false,

        carParksData: [],
        eventsData: [],
        organisationsData: [],
        parkingSpacesData: [],
        fullParkingSpacesData: []
    }),

    computed: {
        /**
         * Computed Headers
         *
         * Remove hidden headers and only show the actions column to those with access.
         *
         * @returns an array of header objects
         */
        computedHeaders() {
            const t = this
            let headers = t.tableHeaders

            headers = headers.filter((h) => !h.hidden)

            return headers
        },

        /**
         * Computed Number of Active Filters
         *
         * Count the number of active filters to display in the filters button badge.
         *
         * @returns {*}
         */
        computedNumberOfActiveFilters() {
            const t = this
            return [t.filterByAttendee ? 1 : 0, t.filterByCarParks.length, t.filterBySpacesRequired ? 1 : 0].reduce((acc, curr) => acc + curr, 0)
        },

        /**
         * Computed Table Data
         *
         * Sort and return the form data.
         *
         * @returns {array[{}]} an array of objects
         */
        computedTableData() {
            const t = this
            let tableData = t.fullParkingSpacesData

            // Search by Event Name
            if (t.searchByEventName) {
                const SEARCH_EVENT_NAME = t.searchByEventName.toUpperCase()
                tableData = tableData.filter((item) => {
                    const EVENT_NAME = item.eventData.eventName.toUpperCase()
                    return EVENT_NAME.includes(SEARCH_EVENT_NAME)
                })
            }

            // Filter by Attendee (this is either an Organisation, Individual, or on the Reserve list)
            if (t.filterByAttendee) {
                const filterByAttendeeUpperCase = t.filterByAttendee?.toUpperCase()
                tableData = tableData.filter((item) => {
                    // Search by Individual Name
                    if (item.individualData && item.individualData?.parkingSpaceIndividualName?.toUpperCase().includes(filterByAttendeeUpperCase))
                        return true

                    // Search by Organisation Name
                    if (item.organisationData && item.organisationData?.organisationName?.toUpperCase().includes(filterByAttendeeUpperCase))
                        return true

                    // Search by Reserved Name
                    if (item.reservedData) {
                        // Check if any reservedName within the reservedData array matches the search term
                        return item.reservedData.some((reservedItem) => {
                            // Check if the reservedName includes the search term (case-insensitive)
                            return reservedItem?.reservedName?.toUpperCase().includes(filterByAttendeeUpperCase)
                        })
                    }

                    return false
                })
            }

            // Filter by Car Park Name
            if (t.filterByCarParks.length) {
                tableData = tableData.filter((item) => {
                    return item?.parkingData?.some((parkingItem) => {
                        return t.filterByCarParks.some((filterCarParkName) => {
                            return parkingItem?.carParkData?.carParkName?.includes(filterCarParkName)
                        })
                    })
                })
            }

            // Filter by Total Spaces Required
            if (t.filterBySpacesRequired) {
                tableData = tableData.filter((item) => t.getTotalNumberOfSpacesRequired(item.parkingData) === parseInt(t.filterBySpacesRequired))
            }

            // Sort by Event Date, and then attendee name (either organisation or individual)
            tableData = tableData
                .sort((a, b) => (a.eventData.eventDate > b.eventData.eventDate ? 1 : -1))
                .sort((a, b) => {
                    const aAttendee = a.organisationData ? a.organisationData.organisationName : a.individualData?.parkingSpaceIndividualName
                    const bAttendee = b.organisationData ? b.organisationData.organisationName : b.individualData?.parkingSpaceIndividualName
                    return aAttendee > bAttendee ? 1 : -1
                })

            return tableData
        }
    },

    methods: {
        /**
         * Clear Filters
         *
         * Clear all the filters.
         */
        clearFilters() {
            const t = this

            t.filterByAttendee = ''
            t.filterByCarParks = []
            t.filterBySpacesRequired = ''
        },

        /**
         * Close Right Panel
         *
         * Close the right panel.
         */
        closeRightPanel() {
            const t = this

            t.tabs = ''
            t.selectedItem = {}
            t.isRightPanelVisible = false
        },

        /**
         * Close Send Qr Code Dialog
         *
         * Close the send QR code dialog.
         */
        closeSendQrCodeDialog() {
            const t = this

            t.isSendQrCodeDialogVisible = false
        },

        /**
         * Edit Item
         *
         * Toggle the editability of the form.
         */
        editItem() {
            const t = this

            t.isReadOnly = !t.isReadOnly
        },

        /**
         * Emitted Close Send Qr Code Dialog
         *
         * Call to close the send QR code dialog.
         */
        emittedCloseSendQrCodeDialog() {
            const t = this

            t.closeSendQrCodeDialog()
        },

        /**
         * Emitted Handle Selected Parking Space
         *
         * Assign the selected parking space to the selected parking item, and show the form.
         * @param parkingSpace
         */
        emittedHandleSelectedParkingSpace(parkingSpace) {
            const t = this

            t.selectedParkingItem = { ...parkingSpace }
            t.tabs = 'form'
            t.shouldFormBeVisible = true
        },

        /**
         * Emitted Reload Page
         *
         * Close the right panel and reload the data to refresh the page.
         */
        emittedReloadPage() {
            const t = this

            t.closeRightPanel()
            t.loadData()
        },

        /**
         * Get Number Of Spaces Required
         *
         * Calculate the number of spaces required for the given item (regular and disabled).
         *
         * @param item - the item to calculate the number of spaces required for
         * @returns {{disabledSpaces: (*|number), totalSpaces: (*|number), regularSpaces: (*|number)}}
         */
        getNumberOfSpacesRequired(item) {
            let regularSpaces = 0
            let disabledSpaces = 0
            let totalSpaces = 0

            regularSpaces =
                item.parkingSpaceNumberOfRegularSmallVehiclesRequired +
                item.parkingSpaceNumberOfRegularMediumVehiclesRequired +
                item.parkingSpaceNumberOfRegularLargeVehiclesRequired
            disabledSpaces =
                item.parkingSpaceNumberOfDisabledSmallVehiclesRequired +
                item.parkingSpaceNumberOfDisabledMediumVehiclesRequired +
                item.parkingSpaceNumberOfDisabledLargeVehiclesRequired
            totalSpaces = regularSpaces + disabledSpaces

            return { regularSpaces, disabledSpaces, totalSpaces }
        },

        /**
         * Get Total Number Of Spaces Required
         *
         * Calculate the total number of spaces required for the given spaces data (regular and disabled).
         *
         * @param spacesData - the spaces data to calculate the number of spaces required for
         * @returns {number} - the total number of spaces required
         */
        getTotalNumberOfSpacesRequired(spacesData) {
            let regularSpaces = 0
            let disabledSpaces = 0

            // If spacesData has a ReserveList, calculate the number of spaces with a name and email
            if (spacesData[0].parkingSpaceData.parkingSpaceReservedList) {
                const RESERVE_LIST =
                    typeof spacesData[0].parkingSpaceData.parkingSpaceReservedList === 'string'
                        ? JSON.parse(spacesData[0].parkingSpaceData.parkingSpaceReservedList)
                        : spacesData[0].parkingSpaceData.parkingSpaceReservedList
                return RESERVE_LIST.filter((item) => item.reservedName && item.reservedEmail)?.length
            }

            // Otherwise, calculate the number of spaces with a vehicle
            spacesData.forEach((item) => {
                const parkingSpaceData = item.parkingSpaceData
                regularSpaces +=
                    parkingSpaceData.parkingSpaceNumberOfRegularSmallVehiclesRequired +
                    parkingSpaceData.parkingSpaceNumberOfRegularMediumVehiclesRequired +
                    parkingSpaceData.parkingSpaceNumberOfRegularLargeVehiclesRequired
                disabledSpaces +=
                    parkingSpaceData.parkingSpaceNumberOfDisabledSmallVehiclesRequired +
                    parkingSpaceData.parkingSpaceNumberOfDisabledMediumVehiclesRequired +
                    parkingSpaceData.parkingSpaceNumberOfDisabledLargeVehiclesRequired
            })

            return regularSpaces + disabledSpaces
        },

        /**
         * Handle Emitted Menu Selection
         *
         * Take the emitted selection from the menu, and call the relevant function.
         *
         * @param selection a string-based menu option
         */
        handleEmittedMenuSelection(selection) {
            const t = this

            if (selection === 'Event Parking') t.openRightPanel(selection)
        },

        /**
         * Load Car Parks Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadCarParksData() {
            const t = this

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getAll('carPark')

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Car Parks: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
                return
            }

            // Assign the data to the state
            t.carParksData = RESPONSE.data
        },

        /**
         * Load Data
         *
         * Load all the data required for the page.
         *
         * @returns {Promise<void>}
         */
        async loadData() {
            const t = this

            t.isLoading = true

            await Promise.all([
                t.loadCarParksData(),
                t.loadEventsData(),
                t.loadOrganisationsData(),
                t.loadParkingSpacesData(),
                t.loadFullParkingSpacesData()
            ])

            t.isLoading = false
        },

        /**
         * Load Event Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadEventsData() {
            const t = this

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getAll('event')

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Events: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
                return
            }

            // Assign the data to the state
            t.eventsData = RESPONSE.data
        },

        /**
         * Load Full Parking Spaces Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadFullParkingSpacesData() {
            const t = this

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getFullParkingSpaces()

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Full Parking Spaces: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
                return
            }

            // Assign the data to the state
            t.fullParkingSpacesData = RESPONSE.data
        },

        /**
         * Load Organisations Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadOrganisationsData() {
            const t = this

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getAll('organisation')

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Organisations: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
                return
            }

            // Assign the data to the state
            t.organisationsData = RESPONSE.data
        },

        /**
         * Load Parking Spaces Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadParkingSpacesData() {
            const t = this

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getAll('parkingSpace')

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Parking Spaces: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem loading this page, please try again.'
                return
            }

            // Assign the data to the state
            t.parkingSpacesData = RESPONSE.data
        },

        /**
         * Open Form
         *
         * Open the form dialog.
         * If no item is passed in, the form will open as new (blank).
         *
         * @param item the selected key
         */
        openRightPanel(item) {
            const t = this

            // If an item is passed in, this is an existing item
            if (item.parkingData?.length) {
                t.isReadOnly = true
                t.selectedItem = { ...item }
                t.tabs = 'current'
                t.shouldFormBeVisible = false
            }

            // Otherwise, create a new item
            else {
                t.isReadOnly = false
                t.selectedItem = {}
                t.tabs = 'form'
                t.selectedParkingItem = {}
                t.shouldFormBeVisible = true
            }

            t.selectedParkingType = 'Event Parking'
            t.isRightPanelVisible = true
        },

        /**
         * Open Send QR Code Dialog
         *
         * Open the send QR code dialog.
         */
        openSendQrCodeDialog() {
            const t = this

            t.isSendQrCodeDialogVisible = true
        },

        /**
         * Send QR Code
         *
         * Send the parkingSpaceIds to the server to generate and send the QR code.
         * @returns {Promise<void>}
         */
        async sendQrCode() {
            const t = this

            // Show loading indicator
            if (t.$store && t.$store.commit) {
                t.$store.commit('setLoading', true)
            }

            try {
                if (!t.selectedItem || !t.selectedItem.parkingData || !Array.isArray(t.selectedItem.parkingData)) {
                    console.error('Invalid selected item structure:', t.selectedItem)
                    t.$sharedState.errorMessage = 'No parking spaces found to send QR codes for.'
                    return
                }

                // Extract the actual parkingSpaceData objects directly
                const parkingSpaceDataArray = t.selectedItem.parkingData
                    .map((parking) => {
                        if (parking && parking.parkingSpaceData) {
                            return parking.parkingSpaceData
                        }
                        return null
                    })
                    .filter(Boolean)

                if (parkingSpaceDataArray.length === 0) {
                    t.$sharedState.errorMessage = 'No parking spaces found to send QR codes for.'
                    return
                }

                // Call the mixin function with the array of parking space data objects
                // The mixin will extract the IDs from these objects
                const RESPONSE = await t.MIX_redis_sendParkingQrCode(parkingSpaceDataArray, '#666666')

                // Handle any errors
                if (RESPONSE.hasErrors) {
                    console.error('Error sending QR code', RESPONSE.error)
                    t.$sharedState.errorMessage = 'There was a problem sending the QR code. Please try again.'
                    return
                }

                // Show success message with more details if available
                if (RESPONSE.data && typeof RESPONSE.data === 'object' && RESPONSE.data.successCount !== undefined) {
                    t.$sharedState.successMessage = `QR code sent successfully. ${RESPONSE.data.successCount} email(s) sent.`

                    // Show warning if some failed
                    if (RESPONSE.data.failureCount > 0) {
                        t.$sharedState.warningMessage = `${RESPONSE.data.failureCount} email(s) failed to send. Please check logs for details.`
                    }
                } else {
                    t.$sharedState.successMessage = 'QR code sent successfully'
                }

                t.closeSendQrCodeDialog()
            } catch (error) {
                console.error('Frontend error sending QR code:', error)
                t.$sharedState.errorMessage = 'There was a problem sending the QR code. Please try again.'
            } finally {
                if (t.$store && t.$store.commit) {
                    t.$store.commit('setLoading', false)
                }
            }
        },

        /**
         * Toggle Filters Visibility
         *
         * Toggle the visibility of the filters panel.
         */
        toggleFiltersVisibility() {
            const t = this

            t.isFiltersPanelVisible = !t.isFiltersPanelVisible
        }
    },

    async created() {
        const t = this

        await t.loadData()
    }
}
</script>

<style scoped></style>
