<!-- Swapp History -->
<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <!--Page Content-->
    <div v-else class="swapp-history-container">
        <!--Header-->
        <page-title :divider="true" icon="timeMachine" info="View all SWAPP history logs." title="SWAPP History" />

        <!--Action Bar-->
        <div class="d-flex align-center mt-4">
            <!--Search-->
            <app-form-field
                form-type="textInput"
                append-icon="icons8-search"
                class="mr-4"
                :clearable="true"
                label="Search"
                style="width: 100%"
                v-model.trim="searchTerm"
            />

            <!--Filter Button - with a numbered badge-->
            <div style="position: relative">
                <app-btn @click.native="toggleFiltersVisibility" class="mr-4" color="appWhite" icon="filter" icon-color="primary" />
                <span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
            </div>

            <!--Export Button-->
            <app-btn @click.native="exportData" icon="export" label="Export" />
        </div>

        <!--Clear Filters-->
        <div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
            <app-btn @click.native="clearFilters" icon="close" label="Clear Filters" />
        </div>

        <!--Table-->
        <v-data-table class="appWhite rounded-lg mt-4" :headers="computedHeaders" :items="computedTableData" :loading="isLoading">
            <!--ID-->
            <template v-slot:item.entityId="{ item }">
                <app-text size="small">{{ item.entityId }}</app-text>
            </template>

            <!--Status-->
            <template v-slot:item.swappDirection="{ item }">
                <v-chip
                    class="d-flex justify-center"
                    :color="item.swappDirection === 'In' ? 'green' : 'red'"
                    small
                    style="width: 48px"
                    text-color="white"
                >
                    {{ item.swappDirection }}
                </v-chip>
            </template>

            <!--Date-->
            <template v-slot:item.swappDate="{ item }">
                <app-text size="small">{{ MIX_formatDate(Number(item.swappDate), 'short') }}</app-text>
                <app-text color="grey9" size="small">@ {{ MIX_formatDateTimeToTime(Number(item.swappDate)) }}</app-text>
            </template>

            <!--User-->
            <template v-slot:item.userName="{ item }">
                <app-text size="small">{{ item.userName || 'Unknown User' }}</app-text>
            </template>

            <!--Site-->
            <template v-slot:item.swappSiteName="{ item }">
                <app-text size="small">{{ item.swappSiteName }}</app-text>
                <app-text color="grey9" size="small">{{ item.swappLocationName }}</app-text>
            </template>

            <!--Method-->
            <template v-slot:item.swappMethod="{ item }">
                <app-text size="small">{{ item.swappMethod }}</app-text>
            </template>

            <!--Event-->
            <template v-slot:item.eventName="{ item }">
                <app-text v-if="item.eventName" size="small">{{ item.eventName }}</app-text>
                <app-text v-if="item.eventName" color="grey9" size="small">{{ MIX_formatDate(Number(item.eventDate), 'short') }}</app-text>
            </template>
        </v-data-table>

        <!--Filters Panel-->
        <filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility" style="z-index: 20">
            <div class="pa-4">
                <!--Direction-->
                <page-break-title title="Direction" />
                <div class="mt-4" style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
                    <app-btn
                        @click.native="toggleDirectionFilter('In')"
                        :block="true"
                        color="green"
                        label="In"
                        :outlined="!filterByDirection.includes('In')"
                    />
                    <app-btn
                        @click.native="toggleDirectionFilter('Out')"
                        :block="true"
                        color="red"
                        label="Out"
                        :outlined="!filterByDirection.includes('Out')"
                    />
                </div>

                <!--Event-->
                <page-break-title class="mt-8" title="Event" />
                <div class="mt-4">
                    <app-form-field form-type="select" label="Event" :items="computedEventsList" v-model="filterByEvent" :clearable="true" />
                </div>

                <!--User-->
                <page-break-title class="mt-8" title="User" />
                <div class="mt-4">
                    <app-form-field form-type="select" label="User" :items="computedUsersList" v-model="filterByUser" :clearable="true" />
                </div>

                <!--Date Range-->
                <page-break-title class="mt-8" title="Date Range" />
                <div class="mt-4">
                    <!--Start Date-->
                    <div class="mb-4">
                        <date-picker
                            @emitDate="handleDateSelection($event, 'startDate')"
                            @clear="clearDateFilter('startDate')"
                            :clearable="true"
                            :date="filterByDateRange.startDate"
                            label="From Date"
                            :min-date="[100, 'years', 'past']"
                            :max-date="getEndDateMaxConstraint()"
                        />
                    </div>

                    <!--End Date-->
                    <div>
                        <date-picker
                            @emitDate="handleDateSelection($event, 'endDate')"
                            @clear="clearDateFilter('endDate')"
                            :clearable="true"
                            :date="filterByDateRange.endDate"
                            label="To Date"
                            :min-date="getStartDateMinConstraint()"
                            :max-date="[100, 'years', 'future']"
                        />
                    </div>
                </div>
            </div>
        </filter-panel>
    </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue'
import FilterPanel from '@/components/FilterPanel.vue'
import PageBreakTitle from '@/components/PageBreakTitle.vue'

export default {
    name: 'SwappHistory',

    components: {
        DatePicker,
        FilterPanel,
        PageBreakTitle
    },

    data: () => ({
        isLoading: false,
        searchTerm: '',
        isFiltersPanelVisible: false,
        filterByEvent: null,
        filterByUser: null,
        filterByDirection: [],
        filterByDateRange: {
            startDate: null,
            endDate: null
        },
        tableHeaders: [
            { text: 'ID', value: 'entityId', align: 'start', sortable: true, hidden: true },
            { text: 'Direction', value: 'swappDirection', align: 'left', sortable: true, width: '120px' },
            { text: 'Date & Time', value: 'swappDate', align: 'start', sortable: true, width: '150px' },
            { text: 'User', value: 'userName', align: 'start', sortable: true },
            { text: 'Site & Location', value: 'swappSiteName', align: 'start', sortable: true },
            { text: 'Method', value: 'swappMethod', align: 'start', sortable: true },
            { text: 'Event', value: 'eventName', align: 'start', sortable: true }
        ],

        // Data
        swappHistoryData: [],
        usersData: [],
        eventsData: []
    }),

    computed: {
        /**
         * Computed Headers
         *
         * Filter hidden headers
         *
         * @returns {Array} the headers for the table
         */
        computedHeaders() {
            return this.tableHeaders.filter((h) => !h.hidden)
        },

        /**
         * Computed Events List
         *
         * Get unique list of events for dropdown
         *
         * @returns {Array} array of event objects with text and value properties
         */
        computedEventsList() {
            const t = this
            const uniqueEvents = []
            const eventIds = new Set()

            // Get unique events from swapp history
            t.swappHistoryData.forEach((item) => {
                if (item.eventId && item.eventName && !eventIds.has(item.eventId)) {
                    uniqueEvents.push({
                        text: item.eventName,
                        value: item.eventId
                    })
                    eventIds.add(item.eventId)
                }
            })

            return uniqueEvents.sort((a, b) => a.text.localeCompare(b.text))
        },

        /**
         * Computed Users List
         *
         * Get unique list of users for dropdown
         *
         * @returns {Array} array of user objects with text and value properties
         */
        computedUsersList() {
            const t = this
            const uniqueUsers = []
            const userIds = new Set()

            // Get unique users from swapp history
            t.swappHistoryData.forEach((item) => {
                if (item.swappUserId && item.userName && !userIds.has(item.swappUserId)) {
                    uniqueUsers.push({
                        text: item.userName,
                        value: item.swappUserId
                    })
                    userIds.add(item.swappUserId)
                }
            })

            return uniqueUsers.sort((a, b) => a.text.localeCompare(b.text))
        },

        /**
         * Computed Number of Active Filters
         *
         * Count the number of active filters to display in the filters button badge.
         *
         * @returns {Number} the count of active filters
         */
        computedNumberOfActiveFilters() {
            const t = this
            // Using regular variables for clarity
            const eventFilter = t.filterByEvent ? 1 : 0
            const userFilter = t.filterByUser ? 1 : 0
            const directionFilters = t.filterByDirection.length
            const startDateFilter = t.filterByDateRange.startDate ? 1 : 0
            const endDateFilter = t.filterByDateRange.endDate ? 1 : 0

            // Sum all active filters
            return eventFilter + userFilter + directionFilters + startDateFilter + endDateFilter
        },

        /**
         * Computed Table Data
         *
         * Filter and sort the data
         *
         * @returns {Array} the filtered table data
         */
        computedTableData() {
            const t = this
            let tableData = t.swappHistoryData

            // Apply search filter if provided
            if (t.searchTerm) {
                const searchValue = t.searchTerm.toLowerCase()
                tableData = tableData.filter((item) => {
                    const userName = (item.userName || '').toLowerCase()
                    const swappSiteName = (item.swappSiteName || '').toLowerCase()
                    const swappLocationName = (item.swappLocationName || '').toLowerCase()
                    const swappMethod = (item.swappMethod || '').toLowerCase()
                    const eventName = (item.eventName || '').toLowerCase()

                    return (
                        userName.includes(searchValue) ||
                        swappSiteName.includes(searchValue) ||
                        swappLocationName.includes(searchValue) ||
                        swappMethod.includes(searchValue) ||
                        eventName.includes(searchValue)
                    )
                })
            }

            // Filter by Event
            if (t.filterByEvent) {
                tableData = tableData.filter((item) => item.eventId === t.filterByEvent)
            }

            // Filter by User
            if (t.filterByUser) {
                tableData = tableData.filter((item) => item.swappUserId === t.filterByUser)
            }

            // Filter by Direction
            if (t.filterByDirection.length) {
                tableData = tableData.filter((item) => t.filterByDirection.includes(item.swappDirection))
            }

            // Filter by Date Range
            if (t.filterByDateRange.startDate) {
                tableData = tableData.filter((item) => Number(item.swappDate) >= t.filterByDateRange.startDate)
            }

            if (t.filterByDateRange.endDate) {
                // Add one day to include the end date fully
                const endTimestamp = t.filterByDateRange.endDate + 24 * 60 * 60 * 1000
                tableData = tableData.filter((item) => Number(item.swappDate) <= endTimestamp)
            }

            // Sort by date descending
            tableData.sort((a, b) => (a.swappDate < b.swappDate ? 1 : -1))

            return tableData
        }
    },

    methods: {
        /**
         * Get Start Date Min Constraint
         *
         * Returns the proper min-date constraint for the end date picker
         * based on the selected start date
         *
         * @returns {Array} The min-date constraint array
         */
        getStartDateMinConstraint() {
            const t = this

            if (t.filterByDateRange.startDate) {
                // If we have a start date, use it as a constraint for end date
                return [0, 'days', 'past'] // Allow selecting the same day or future days
            }

            // Default: allow any date in the past
            return [100, 'years', 'past']
        },

        /**
         * Get End Date Max Constraint
         *
         * Returns the proper max-date constraint for the start date picker
         * based on the selected end date
         *
         * @returns {Array} The max-date constraint array
         */
        getEndDateMaxConstraint() {
            const t = this

            if (t.filterByDateRange.endDate) {
                // If we have an end date, use it as a constraint for start date
                return [0, 'days', 'future'] // Allow selecting the same day or past days
            }

            // Default: allow any date in the future
            return [100, 'years', 'future']
        },

        /**
         * Handle Date Selection
         *
         * Takes the emitted date from the DatePicker component and
         * sets it to the specified field in the filterByDateRange object.
         *
         * @param {Number} date - The timestamp of the selected date
         * @param {String} field - The field to set (startDate or endDate)
         */
        handleDateSelection(date, field) {
            const t = this

            // Validate the date before storing it
            if (date && !isNaN(Number(date))) {
                // Store the date directly in the object
                t.$set(t.filterByDateRange, field, Number(date))
            }
        },

        /**
         * Clear Date Filter
         *
         * Explicitly handles the clear event from date-picker component
         *
         * @param {String} field - The field to clear (startDate or endDate)
         */
        clearDateFilter(field) {
            const t = this

            // Set field to null using Vue.$set to ensure reactivity
            t.$set(t.filterByDateRange, field, null)
        },

        /**
         * Clear Filters
         *
         * Clear all filters
         */
        clearFilters() {
            const t = this

            t.filterByEvent = null
            t.filterByUser = null
            t.filterByDirection = []

            // Clear date filters individually using $set for proper reactivity
            t.$set(t.filterByDateRange, 'startDate', null)
            t.$set(t.filterByDateRange, 'endDate', null)
        },

        /**
         * Toggle Direction Filter
         *
         * Add or remove a direction filter
         *
         * @param {String} direction - The direction to toggle
         */
        toggleDirectionFilter(direction) {
            const t = this

            if (t.filterByDirection.includes(direction)) {
                t.filterByDirection = t.filterByDirection.filter((d) => d !== direction)
            } else {
                t.filterByDirection.push(direction)
            }
        },

        /**
         * Toggle Filters Visibility
         *
         * Show or hide the filters panel
         */
        toggleFiltersVisibility() {
            const t = this

            t.isFiltersPanelVisible = !t.isFiltersPanelVisible
        },

        /**
         * Export Data
         *
         * Export the data to CSV
         */
        exportData() {
            const t = this

            const headers = {
                entityId: 'ID',
                swappDate: 'Date & Time',
                swappDirection: 'Direction',
                userName: 'User',
                swappSiteName: 'Site',
                swappLocationName: 'Location',
                swappMethod: 'Method',
                eventName: 'Event',
                eventDate: 'Event Date'
            }

            // Create formatted data for export that properly handles all fields
            const data = t.computedTableData.map((entry) => {
                // Handle each field with proper formatting and null checks
                return {
                    entityId: entry.entityId || '', // Ensure entityId isn't undefined
                    swappDate: entry.swappDate
                        ? `${t.MIX_formatDate(Number(entry.swappDate), 'short')} ${t.MIX_formatDateTimeToTime(Number(entry.swappDate))}`
                        : '',
                    swappDirection: entry.swappDirection || '',
                    userName: entry.userName || 'Unknown User',
                    swappSiteName: entry.swappSiteName || '',
                    swappLocationName: entry.swappLocationName || '',
                    swappMethod: entry.swappMethod || '',
                    eventName: entry.eventName || '',
                    eventDate: entry.eventDate ? t.MIX_formatDate(Number(entry.eventDate), 'short') : ''
                }
            })

            // Pass to external helper for actual export
            t.MIX_exportDocuments(headers, 'SWAPP History', data)
        },

        /**
         * Load Data
         *
         * Load all the data required for the page.
         *
         * @returns {Promise<void>}
         */
        async loadData() {
            const t = this
            t.isLoading = true

            try {
                // First load user data
                await t.loadUsersData()

                // Then load swapp history data and process it
                await t.loadSwappHistoryData()

                // Load events data
                await t.loadEventsData()

                // Process the data to add user names and event info
                t.processSwappHistoryData()
            } catch (error) {
                console.error('Error loading data:', error)
                t.$sharedState.errorMessage = 'There was a problem loading the data. Please try again.'
            } finally {
                t.isLoading = false
            }
        },

        /**
         * Load Swapp History Data
         *
         * Load the swapp history data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadSwappHistoryData() {
            const t = this

            // FIXED: Added entityId to the fields list to ensure it's available for exports
            const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
                'SwappHistory',
                [], // No filter to get all users' data
                [
                    { field: 'entityId' }, // Added entityId explicitly
                    { field: 'swappDate' },
                    { field: 'swappDirection' },
                    { field: 'swappSiteName' },
                    { field: 'swappLocationName' },
                    { field: 'swappMethod' },
                    { field: 'swappUserId' }
                ]
            )

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error loading Swapp History data: ', RESPONSE.errors)
                t.$sharedState.errorMessage = 'There was a problem loading the Swapp History data, please try again.'
                return
            }

            t.swappHistoryData = RESPONSE.data
        },

        /**
         * Load Users Data
         *
         * Load user data for mapping user IDs to names.
         *
         * @returns {Promise<void>}
         */
        async loadUsersData() {
            const t = this

            const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields('User', [], [{ field: 'entityId' }, { field: 'userName' }])

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error loading Users data: ', RESPONSE.errors)
                t.$sharedState.errorMessage = 'There was a problem loading the Users data, please try again.'
                return
            }

            t.usersData = RESPONSE.data
        },

        /**
         * Load Events Data
         *
         * Load events data to match with SWAPP history entries.
         *
         * @returns {Promise<void>}
         */
        async loadEventsData() {
            const t = this

            // Get all events with their dates to match with SWAPP history
            const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
                'Event',
                [], // No filter to get all events
                [
                    { field: 'entityId' },
                    { field: 'eventName' },
                    { field: 'eventDate' },
                    { field: 'eventEndDate' } // Including end date to match date ranges
                ]
            )

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error loading Events data: ', RESPONSE.errors)
                t.$sharedState.errorMessage = 'There was a problem loading the Events data, please try again.'
                return
            }

            t.eventsData = RESPONSE.data
        },

        /**
         * Process Swapp History Data
         *
         * Add user names and event information to the swapp history data
         */
        processSwappHistoryData() {
            const t = this

            // Create a map of user IDs to names for quick lookup
            const userMap = {}
            t.usersData.forEach((user) => {
                userMap[user.entityId] = user.userName
            })

            // Add user names to the swapp history data
            t.swappHistoryData.forEach((item) => {
                // Add user name
                item.userName = userMap[item.swappUserId] || 'Unknown User'

                // Try to match with an event based on the SWAPP date
                const swappTimestamp = Number(item.swappDate)
                const matchingEvent = t.findMatchingEvent(swappTimestamp)

                if (matchingEvent) {
                    item.eventName = matchingEvent.eventName
                    item.eventDate = matchingEvent.eventDate
                    item.eventId = matchingEvent.entityId
                }
            })
        },

        /**
         * Find Matching Event
         *
         * Find an event that matches the given timestamp.
         * An event matches if the timestamp falls within the event's start and end dates.
         *
         * @param {number} timestamp - The SWAPP timestamp to match
         * @returns {Object|null} - The matching event or null if no match
         */
        findMatchingEvent(timestamp) {
            const t = this

            // Find an event where the SWAPP timestamp falls within the event dates
            return (
                t.eventsData.find((event) => {
                    const eventStartDate = Number(event.eventDate)
                    const eventEndDate = Number(event.eventEndDate || event.eventDate) // Use end date if available, otherwise use start date

                    // Add a day to end date to include the whole day
                    const eventEndDatePlusDay = eventEndDate + 24 * 60 * 60 * 1000

                    // Check if the SWAPP timestamp falls within the event date range
                    return timestamp >= eventStartDate && timestamp <= eventEndDatePlusDay
                }) || null
            )
        }
    },

    mounted() {
        this.loadData()
    }
}
</script>

<style scoped>
/* Only keep the filter panel styling, let the badge use global styling */
::v-deep .filter-panel {
    z-index: 10 !important;
}
</style>
